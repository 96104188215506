<template>
  <div class="subpage page-content">
    <div class="container">
      <div class="page-title">
        <h3>개인정보처리방침</h3>
      </div>
      <div class="content-wrap">
        <h4>총칙</h4>
        <p>
          ㈜퓨처월드포(이하 '회사')는 이용자의 소중한 개인정보를 보호하기 위해 개인정보보호 관련 법령을 준수합니다.<br />
          개인정보처리방침을 통해 회사가 어떤 개인정보를 수집하고, 어떻게 이용하며, 누구에게 제공하는지, 언제 어떻게
          파기하는지 등에 대해 이해할 수 있으며 본 개인정보처리방침은 게임 서비스뿐만 아니라 게임서비스와 직간접적으로
          연관이 있는 부가 서비스에도 적용됩니다.
        </p>

        <h4>1. 개인정보의 수집</h4>
        <p>
          <strong
            >회사는 서비스 이용 시 필요한 개인정보만을 수집하며, 수집 시에는 이용자에게 상세히 안내하고 동의를 받아
            처리하고 있습니다.</strong
          >
        </p>

        <p>
          ■ 필수정보란?<br />
          기본적인 서비스를 제공하기 위해 수집하는 개인정보
        </p>
        <p>
          ■ 선택정보란?<br />
          기본적인 서비스 이외에 부가적인 서비스를 제공하기 위해 수집하는 개인정보
        </p>

        <h4>2. 개인정보의 이용</h4>
        <p>
          <strong
            >회사는 아래의 목적으로 개인정보를 이용하며, 목적이 변경될 경우에는 이용자에게 상세히 안내하고 동의를 받아
            처리하고 있습니다.</strong
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'privacy'
}
</script>
